var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    [
      _c("b-col", { attrs: { cols: "12" } }, [
        _c("p", { staticClass: "primary fs-24 fw-bold py-2" }, [
          _vm._v("Certificados")
        ]),
        _c("p", { staticClass: "text-justify" }, [
          _vm._v(
            " Ten en cuenta que el campo año solo lo necesitas para generar el certificado de declaración de renta "
          )
        ])
      ]),
      _c(
        "b-col",
        { attrs: { cols: "12" } },
        [
          _c(
            "b-form-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12", md: "4" } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: "Número de obligación" } },
                    [
                      _c(
                        "b-form-select",
                        {
                          attrs: {
                            state: _vm.$v.formC.credito.$dirty
                              ? !_vm.$v.formC.credito.$error
                              : null
                          },
                          model: {
                            value: _vm.creditoSelected,
                            callback: function($$v) {
                              _vm.creditoSelected = $$v
                            },
                            expression: "creditoSelected"
                          }
                        },
                        [
                          _c(
                            "b-form-select-option",
                            { attrs: { value: null } },
                            [_vm._v("Seleccione número de obligación")]
                          ),
                          _vm._l(_vm.$store.getters.creditos, function(
                            credito
                          ) {
                            return _c(
                              "b-form-select-option",
                              {
                                key: credito.numeroObligacion,
                                attrs: { value: credito }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      "Nro de Obligación " +
                                        credito.numeroObligacion +
                                        " - " +
                                        credito.estado.toUpperCase()
                                    ) +
                                    " "
                                )
                              ]
                            )
                          })
                        ],
                        2
                      ),
                      _c("b-form-invalid-feedback", [
                        _vm._v(_vm._s(_vm.validarCampo(_vm.$v.formC.credito)))
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "12", md: "4" } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: "Tipo de certificado" } },
                    [
                      _c("b-form-select", {
                        attrs: {
                          options: _vm.tipos,
                          state: _vm.$v.formC.tipo.$dirty
                            ? !_vm.$v.formC.tipo.$error
                            : null
                        },
                        on: { change: _vm.calcularPlantilla },
                        scopedSlots: _vm._u([
                          {
                            key: "first",
                            fn: function() {
                              return [
                                _c(
                                  "b-form-select-option",
                                  { attrs: { value: null } },
                                  [_vm._v("Seleccione")]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ]),
                        model: {
                          value: _vm.$v.formC.tipo.$model,
                          callback: function($$v) {
                            _vm.$set(_vm.$v.formC.tipo, "$model", $$v)
                          },
                          expression: "$v.formC.tipo.$model"
                        }
                      }),
                      _c("b-form-invalid-feedback", [
                        _vm._v(_vm._s(_vm.validarCampo(_vm.$v.formC.tipo)))
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "12", md: "4" } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: "Año" } },
                    [
                      _c("b-form-select", {
                        attrs: {
                          options: _vm.anios,
                          state: _vm.stateAnio,
                          disabled: !_vm.esAnioRequired
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "first",
                            fn: function() {
                              return [
                                _c(
                                  "b-form-select-option",
                                  { attrs: { value: null } },
                                  [_vm._v("Seleccione")]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ]),
                        model: {
                          value:
                            _vm.$v.formC[
                              _vm.esHistorial ? "mayorJunio" : "anio"
                            ].$model,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.$v.formC[
                                _vm.esHistorial ? "mayorJunio" : "anio"
                              ],
                              "$model",
                              $$v
                            )
                          },
                          expression:
                            "$v.formC[esHistorial ? 'mayorJunio' : 'anio'].$model"
                        }
                      }),
                      _c("b-form-invalid-feedback", [
                        _vm._v(_vm._s(_vm.validarCampo(_vm.$v.formC.anio)))
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-col",
        { staticClass: "content-end-center", attrs: { cols: "12" } },
        [
          _c(
            "b-button",
            {
              attrs: { variant: "danger", disabled: _vm.$v.$invalid },
              on: { click: _vm.validarTipoCertificado }
            },
            [_c("i", { staticClass: "las la-search" }), _vm._v(" Consultar ")]
          )
        ],
        1
      ),
      _vm.archivo !== null
        ? [
            _c(
              "b-col",
              { staticClass: "pt-3", attrs: { cols: "12" } },
              [
                _c("b-form-tags", {
                  attrs: {
                    size: "sm",
                    "tag-pills": "",
                    "tag-variant": "info",
                    limitTagsText: "10",
                    addButtonText: "Agregar",
                    disableAddButton: true,
                    placeholder: "Agregar correo",
                    "tag-validator": _vm.validarCorreo,
                    addButtonVariant: "outline-info",
                    invalidTagText: "Correo invalido",
                    duplicateTagText: "Correo repetido"
                  },
                  model: {
                    value: _vm.correosNotificacion,
                    callback: function($$v) {
                      _vm.correosNotificacion = $$v
                    },
                    expression: "correosNotificacion"
                  }
                })
              ],
              1
            ),
            _c(
              "b-col",
              { staticClass: "content-end-center pt-3", attrs: { cols: "12" } },
              [
                _c(
                  "b-button",
                  {
                    attrs: {
                      variant: "danger",
                      disabled: _vm.correosNotificacion.length === 0
                    },
                    on: { click: _vm.enviarNotificacion }
                  },
                  [
                    _c("i", { staticClass: "las la-paper-plane" }),
                    _vm._v(" Enviar ")
                  ]
                )
              ],
              1
            )
          ]
        : _vm._e(),
      _c(
        "b-col",
        {
          staticClass: "content-center my-3",
          attrs: { cols: "12", lg: "10", "offset-lg": "1" }
        },
        [
          _c("div", { staticClass: "content-center pointer-card-info" }, [
            _c("div", { staticClass: "d-none d-sm-block" }, [
              _vm.archivo !== null
                ? _c("embed", {
                    staticClass: "content-pdf",
                    attrs: { type: "application/pdf", src: _vm.archivo }
                  })
                : _c("img", {
                    staticClass: "content-dummie",
                    attrs: {
                      src: require("@/assets/images/anothers/dummieCertificados.png")
                    }
                  })
            ]),
            _c(
              "div",
              { staticClass: "d-block d-sm-none py-4" },
              [
                _c(
                  "b-button",
                  {
                    attrs: {
                      variant: "danger",
                      disabled: _vm.archivo === null
                    },
                    on: { click: _vm.descargarCertificado }
                  },
                  [_vm._v("Descargar")]
                )
              ],
              1
            )
          ])
        ]
      ),
      _c("ModalAceptarCertificado", {
        attrs: { datosModal: _vm.datosModal, modal: _vm.modal },
        on: { sendForm: _vm.aceptarCertificado, useModal: _vm.useModal }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }